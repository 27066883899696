import {
  Flex,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { useIntl } from "react-intl";
import { Icon } from "@raiden/library-ui/components/Icon";
import { RoomPicturesSlider } from "../RoomPicturesSlider/RoomPicturesSlider";
import useTranslate from "@raiden/library-ui/hooks/useTranslate";

/**
 * @typedef {object} Props
 * @property {import("@raiden/library-ui/types/RoomPicture").RoomPicture[]} roomPictures
 * @property {number | null} pictureIndex
 * @property {import("react").Dispatch<import("react").SetStateAction<number | null>>} setPictureIndex
 * @property {boolean} isOpen
 * @property {() => void} onClose
 */
/**
 * @param {Props} props
 */
export function RoomPicturesFullscreenModal({
  roomPictures,
  pictureIndex,
  setPictureIndex,
  isOpen,
  onClose,
}) {
  const intl = useIntl();

  const translate = useTranslate();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="full"
      isCentered={true}
      blockScrollOnMount={false}
      scrollBehavior="inside">
      <ModalOverlay />

      <ModalContent height="full">
        <ModalHeader position="relative">
          <HStack alignItems="center" justifyContent="center" width="full">
            <Text variant="text-md-light" color="gray.500">
              {`${(pictureIndex ?? 0) + 1}/${roomPictures.length}`}
            </Text>

            <IconButton
              position="absolute"
              right="12px"
              top="12px"
              onClick={onClose}
              icon={<Icon icon="ms_close" />}
              aria-label={intl.formatMessage({ defaultMessage: "Fermer" })}
              variant="outline"
              size="sm"
              borderRadius="full"
            />
          </HStack>
        </ModalHeader>

        <ModalBody>
          <RoomPicturesSlider
            roomPictures={roomPictures}
            pictureIndex={pictureIndex}
            setPictureIndex={setPictureIndex}
          />
        </ModalBody>

        <ModalFooter>
          {pictureIndex !== null && (
            <Flex
              maxWidth="1100px"
              marginX="auto"
              flexDir={{ base: "column", md: "row" }}
              justifyContent={{ base: "center", md: "space-between" }}
              width="full"
              gap="2rem"
              px=".25rem"
              height={{ base: "170px", md: "64px" }}>
              {roomPictures[pictureIndex]?.caption && (
                <Text variant="text-sm">
                  {translate(roomPictures[pictureIndex]?.caption)}
                </Text>
              )}

              {roomPictures[pictureIndex]?.copyright && (
                <Text variant="text-sm">
                  {roomPictures[pictureIndex]?.copyright}
                </Text>
              )}
            </Flex>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
