import { AspectRatio, Box, Flex, Grid, useDisclosure } from "@chakra-ui/react";
import PampaImage from "@raiden/library-ui/components/PampaImage";
import { Slider } from "@raiden/library-ui/components/Slider";
import useTranslate from "@raiden/library-ui/hooks/useTranslate";
import { memo, useMemo } from "react";
import { RoomPictureLazy } from "../../../components/SearchHotels/RoomPictureLazy/RoomPictureLazy";
import { ButtonShowAllPictures } from "./ButtonShowAllPictures";

const GRID_AREAS = [
  {
    key: "a",
    maxContainerWidth: 522,
  },
  {
    key: "b",
    maxContainerWidth: 259,
  },
  {
    key: "c",
    maxContainerWidth: 259,
  },
  {
    key: "d",
    maxContainerWidth: 259,
  },
  {
    key: "e",
    maxContainerWidth: 259,
  },
];

export const HotelViewPictures = memo(
  /**
   * @typedef {object} Props
   * @property {import("@raiden/library-ui/types/RoomPicture").RoomPicture[]} [roomPictures]
   */
  /**
   * @param {Props} props
   */
  function HotelViewPictures({ roomPictures }) {
    const translate = useTranslate();

    const {
      isOpen: isOpenModalPictures,
      onOpen: onOpenModalPictures,
      onClose: onCloseModalPictures,
    } = useDisclosure();

    const filteredRoomPictures = useMemo(() => {
      const first5Pictures =
        roomPictures?.filter((_, index) => index < 5) ?? [];
      return first5Pictures;
    }, [roomPictures]);

    if (filteredRoomPictures.length === 0) {
      return null;
    }

    return (
      <Box>
        <Box>
          {filteredRoomPictures.length === 1 ? (
            <Box
              borderRadius="20px"
              overflow="hidden"
              backgroundColor="gray.100"
              position="relative">
              <AspectRatio ratio={4 / 3}>
                <PampaImage
                  image={filteredRoomPictures[0]}
                  mode="crop"
                  format="default"
                  alt={translate(filteredRoomPictures[0].caption) ?? ""}
                />
              </AspectRatio>

              <Box position="absolute" bottom="8px" right="8px">
                <ButtonShowAllPictures
                  roomPictures={roomPictures ?? []}
                  isOpen={isOpenModalPictures}
                  onOpen={onOpenModalPictures}
                  onClose={onCloseModalPictures}
                />
              </Box>
            </Box>
          ) : (
            <>
              <Box display={{ base: "none", lg: "grid" }} position="relative">
                <Grid
                  alignItems="stretch"
                  gridTemplate={`
                  "a a b c"
                  "a a d e"
                  `}
                  gridGap="4px">
                  {filteredRoomPictures.map((roomPicture, index) => {
                    return (
                      <Flex
                        onClick={onOpenModalPictures}
                        cursor="pointer"
                        gridArea={GRID_AREAS[index].key}
                        sx={{
                          "& > *": {
                            flexGrow: 1,
                          },
                        }}
                        borderRadius="20px"
                        overflow="hidden"
                        backgroundColor="gray.100"
                        key={roomPicture.id}>
                        <AspectRatio ratio={4 / 3}>
                          <PampaImage
                            image={roomPicture}
                            mode="crop"
                            format="default"
                            maxContainerWidth={
                              GRID_AREAS[index].maxContainerWidth
                            }
                            alt={translate(roomPicture.caption) ?? ""}
                          />
                        </AspectRatio>
                      </Flex>
                    );
                  })}
                </Grid>

                <Box position="absolute" bottom="8px" right="8px">
                  <ButtonShowAllPictures
                    roomPictures={roomPictures ?? []}
                    isOpen={isOpenModalPictures}
                    onOpen={onOpenModalPictures}
                    onClose={onCloseModalPictures}
                  />
                </Box>
              </Box>

              <Box display={{ base: "block", lg: "none" }}>
                <Slider itemWidth="95%" gradientColor="transparent">
                  {filteredRoomPictures.map((picture) => (
                    <Box
                      onClick={onOpenModalPictures}
                      cursor="pointer"
                      borderRadius="10px"
                      overflow="hidden"
                      key={picture.id}>
                      <RoomPictureLazy
                        roomPicture={picture}
                        maxContainerWidth={910}
                      />
                    </Box>
                  ))}
                </Slider>
              </Box>
            </>
          )}
        </Box>
      </Box>
    );
  },
);
