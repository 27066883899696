import { Box, Flex, IconButton } from "@chakra-ui/react";
import { Icon } from "@raiden/library-ui/components/Icon";
import { useCallback, useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { RoomOriginalPictureLazy } from "../../SearchHotels/RoomOriginalPictureLazy/RoomOriginalPictureLazy";

/**
 * @typedef {object} Props
 * @property {import("@raiden/library-ui/types/RoomPicture").RoomPicture[]} roomPictures
 * @property {number | null} pictureIndex
 * @property {import("react").Dispatch<import("react").SetStateAction<number | null>>} setPictureIndex
 */
/**
 * @param {Props} props
 */
export function RoomPicturesSlider({
  roomPictures,
  pictureIndex,
  setPictureIndex,
}) {
  const intl = useIntl();

  const handleClickPrev = useCallback(() => {
    setPictureIndex((prevIndex) => {
      // Si l'index est null ou déjà au début, on ne le modifie pas.
      if (prevIndex === null || prevIndex <= 0) {
        return prevIndex;
      }
      return prevIndex - 1;
    });
  }, [setPictureIndex]);

  const handleClickNext = useCallback(() => {
    setPictureIndex((prevIndex) => {
      // Si l'index est null ou déjà à la dernière image, on ne le modifie pas.
      if (prevIndex === null || prevIndex >= roomPictures.length - 1) {
        return prevIndex;
      }
      return prevIndex + 1;
    });
  }, [setPictureIndex, roomPictures.length]);

  // Ajout du support des flèches gauche et droite
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "ArrowLeft") {
        handleClickPrev();
      } else if (e.key === "ArrowRight") {
        handleClickNext();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleClickPrev, handleClickNext]);

  // Gestion du swipe pour mobile
  const touchStartX = useRef(null);
  const touchEndX = useRef(null);
  const minSwipeDistance = 50;

  const handleTouchStart = useCallback((e) => {
    touchStartX.current = e.touches[0].clientX;
  }, []);

  const handleTouchMove = useCallback((e) => {
    touchEndX.current = e.touches[0].clientX;
  }, []);

  const handleTouchEnd = useCallback(() => {
    if (touchStartX.current === null || touchEndX.current === null) {
      return;
    }
    const distance = touchStartX.current - touchEndX.current;
    if (distance > minSwipeDistance) {
      // Swipe gauche : on passe à l'image suivante.
      handleClickNext();
    } else if (distance < -minSwipeDistance) {
      // Swipe droite : on revient à l'image précédente.
      handleClickPrev();
    }
    // Réinitialisation
    touchStartX.current = null;
    touchEndX.current = null;
  }, [handleClickNext, handleClickPrev]);

  return (
    <Box
      position="relative"
      height="100%"
      margin={"0 auto"}
      width={"auto"}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}>
      {roomPictures.map((roomPicture, index) => (
        <Flex
          key={roomPicture.id}
          justifyContent="center"
          alignItems="center"
          maxWidth="100%"
          height="100%"
          opacity={index === pictureIndex ? 1 : 0}
          pointerEvents={index === pictureIndex ? "auto" : "none"}
          position="absolute"
          top="0"
          bottom="0"
          left="0"
          right="0"
          transition="opacity 0.5s"
          flexDir="column">
          <Flex
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%"
            position="relative">
            <Box maxWidth="1100px" height="100%" flexGrow={1}>
              <RoomOriginalPictureLazy
                roomPicture={roomPicture}
                maxContainerWidth={1100}
              />
            </Box>
          </Flex>
        </Flex>
      ))}

      <Box
        display={pictureIndex === 0 ? "none" : "block"}
        position="absolute"
        left={{ base: "0px", md: "16px" }}
        top="50%"
        transform={`translateY(-50%)`}
        borderRadius="full">
        <IconButton
          borderRadius="full"
          icon={<Icon icon="ms_chevron_left" size="24px" color="white" />}
          variant="ghost"
          backgroundColor="rgba(11, 13, 27, .5)"
          aria-label={intl.formatMessage({
            defaultMessage: "Précédent",
          })}
          onClick={handleClickPrev}
          _hover={{ backgroundColor: "rgba(11, 13, 27, .7)" }}
        />
      </Box>

      <Box
        display={pictureIndex === roomPictures.length - 1 ? "none" : "block"}
        position="absolute"
        right={{ base: "0px", md: "16px" }}
        top="50%"
        transform={`translateY(-50%)`}
        borderRadius="full">
        <IconButton
          borderRadius="full"
          icon={<Icon icon="ms_chevron_right" size="24px" color="white" />}
          variant="ghost"
          backgroundColor="rgba(11, 13, 27, .5)"
          aria-label={intl.formatMessage({ defaultMessage: "Suivant" })}
          onClick={handleClickNext}
          _hover={{ backgroundColor: "rgba(11, 13, 27, .7)" }}
        />
      </Box>
    </Box>
  );
}
