import { Flex, Box } from "@chakra-ui/react";
import PampaImage from "@raiden/library-ui/components/PampaImage";
import useTranslate from "@raiden/library-ui/hooks/useTranslate";
import { useEffect, useRef, useState } from "react";

/**
 * @typedef {object} Props
 * @property {import("@raiden/library-ui/types/RoomPicture").RoomPicture} roomPicture
 * @property {number} [maxContainerWidth]
 * @property {number} [ratio]
 */
/**
 * @param {Props} props
 */
export function RoomOriginalPictureLazy({
  roomPicture,
  maxContainerWidth = 350,
}) {
  const [isVisible, setIsVisible] = useState(false);

  const ref = useRef(/** @type {HTMLDivElement | null} */ (null));

  const translate = useTranslate();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.01 },
    );

    const currentRef = ref.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [ref]);

  return (
    <Flex ref={ref} w="full" height="full" justifyContent="center">
      {isVisible ? (
        <PampaImage
          image={roomPicture}
          mode="resize"
          maxContainerWidth={maxContainerWidth}
          alt={translate(roomPicture.caption) || "Image"}
          objectFit="contain"
          objectPosition="center"
          width="100%"
          height="100%"
        />
      ) : (
        <Box />
      )}
    </Flex>
  );
}
