/**
 * @param {object} params
 * @param {import("../../../types/Departement").Departement} params.department
 * @param {string} [params.locale]
 * @param {string} [params.defaultLocale]
 * @returns {string}
 */
export function getDepartmentSlug({ department, locale, defaultLocale }) {
  let slug = [department.id.toString()];

  if (department.slug) {
    const departmentSlugLocale = locale && department.slug[locale];
    const departmentSlugDefaultLocale =
      defaultLocale && department.slug[defaultLocale];

    if (departmentSlugLocale) {
      slug.push(departmentSlugLocale);
    } else if (departmentSlugDefaultLocale) {
      slug.push(departmentSlugDefaultLocale);
    }
  }

  return slug.join("-");
}
